/* Container utama */

:root {
  --siapptn-color: #20549d;
  --siapptn-color-disabled: #8fb9f4;
}
.soal-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Loading dan soal */
.soal-loading,
.soal-cerita,
.soal-question {
  text-align: left;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

/* Nomor soal */
.soal-number {
  font-weight: bold;
  color: #ff6b6b;
}

/* Pilihan jawaban */
.soal-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.table-siap {
  overflow-x: auto;
  display: block;
}
.br-1{
  border-radius: 5px;
}

.br-2{
  border-radius: 8px;
}
.br-3{
  border-radius: 10px;
}
.br-4{
  border-radius: 12px;
}
.br-5{
  border-radius: 15px;
}
.btn-siapptn{
  background-color: var(--siapptn-color) !important;
}

img#text {
  width: auto;
  height: 20px;
}
img#text-sedang {
  width: auto;
  height: 35px;
}
img#text-besar {
  width: auto;
  height: 40px;
}
img#text-xl {
  width: auto;
  height: 50px;
}
img#soal-sedang {
  width: 300px;
}
img#soal-kecil {
  margin-top: -5px;
}
img#soal-gede {
  width: auto;
  height: 500px;
}
img#soal\ sedang {
  width: 300px;
}
img#soal\ besar {
  height: auto;
  width: 500px;
}
img#soal\ gede {
  height: auto;
  width: 500px;
}

img#soal-gede-banget {
  width: auto;
  height: 800px;
}
img#pecahan {
  width: auto;
  height: 35px;
}
.soal-options label {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.soal-options label:hover {
  background-color: #e0f7fa;
}

.soal-options input[type="radio"] {
  margin-right: 10px;
}

/* Tombol navigasi */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  background-color: #00acc1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



/* .navigation-buttons button:hover {
  background-color: #00838f;
} */

.navigation-buttons button:disabled {
  background-color: #b2ebf2;
  cursor: not-allowed;
}

/* Tombol simpan */
.save-button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.save-button button {
  background-color: var( --siapptn-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button button:disabled {
  background-color: var(--siapptn-color-disabled);
}





/* Tema Siapcasn */
.siapsan-theme {
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
}

.siapsan-theme h3 {
    color: #aa1825;
}

.siapsan-theme button {
    background-color: #aa1825;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.siapsan-theme button:hover {
    background-color: #b21927;
}

.siapsan-theme label {
    margin-bottom: 10px;
    cursor: pointer;
}

/* Tema Siapptn */
.siapptn-theme {
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
}

.siapptn-theme h3 {
    color: #1d57a5;
}

.siapptn-theme button {
    background-color: #1d57a5;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.siapptn-theme button:hover {
    background-color: #1f67b5;
}

.siapptn-theme label {
    margin-bottom: 10px;
    cursor: pointer;
}

/* Loader */
.loading {
    text-align: center;
    font-size: 1.2em;
    color: #999;
}

/* Container */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
